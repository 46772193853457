body {
  background: url(./images/stars_bg_full.jpg);
  background-size: cover;
  height: 120vh;
  width: 100vw;
}

@media screen and (max-width: 700px) {
  body {
    background: url(./images/stars_bg_full.jpg);
    background-size: cover;
    height: 100vh;
    width: 100vw;
  }
}
